import React from 'react';
import { AppContext } from '../data/Context';
import Gallery from './Gallery';

export const Projects = () => {
  return (
    <div className="text-center">
      <AppContext.Consumer>{context => (<strong><h1>{context.lang.projectHeader}</h1></strong>)}</AppContext.Consumer>
      <hr style={{ borderColor: 'Black' }} />
      <AppContext.Consumer>{context => (<div dangerouslySetInnerHTML={{ __html: context.lang.projectContent }} />)}</AppContext.Consumer>
      <Gallery images={[{
        src: 'images/projects/01.jpeg',
        thumbnail: 'images/projects/01_thumbnail.jpeg'
      },
      {
        src: 'images/projects/02.jpeg',
        thumbnail: 'images/projects/02_thumbnail.jpeg'
      },
      {
        src: 'images/projects/03.jpeg',
        thumbnail: 'images/projects/03_thumbnail.jpeg'
      }]} />
    </div>
  );
}