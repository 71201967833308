import React from 'react'
import { lang } from './Language';

export class LanguageContext {
    public lang: any;

    constructor(lan: 'en' | 'de'){
        this.lang = lang[lan];
    }

    changeLanguage = (lan: 'de' | 'en') => {
        this.lang = lang[lan];
    };

    getContent = (id: string) => {
        return this.lang[id];
      }
}
export const AppContext = React.createContext(new LanguageContext('de'));