import React from 'react';

export const RowComponent = (props) => {
  return (
    <section id={props.id} ref={props.refFunc}>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          {props.component()}
        </div>
      </div>
    </section>
  );
}