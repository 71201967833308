import React from 'react';
import { AppContext } from '../data/Context';
import Slider from 'react-animated-slider';
import { SliderItems } from '../data/SliderData';
import 'react-animated-slider/build/horizontal.css';
import '../slider-animations.css';
import '../slider-styles.css';

export const SliderComponent = (props) => {
  return (
    <Slider className="slider-wrapper" autoplay="5000" >
      {SliderItems.map((item, index) => (
        <div
          key={index}
          className="slider-content"
          style={{ background: `url('${item.getImagePath()}') no-repeat center center` }}
        >
          <div className="inner">
            <SliderTitle slideritem={item} />
            <SliderDescription slideritem={item} />
          </div>
        </div>
      ))}
    </Slider>
  );
}

const SliderTitle = (props) => {
  return (
    <AppContext.Consumer>
      {context => (<h1>{context.getContent(`slider_${props.slideritem.id}_title`)}</h1>)}
    </AppContext.Consumer>

  );
}

const SliderDescription = (props) => {
  return (
    <AppContext.Consumer>
      {context => (<p>{context.getContent(`slider_${props.slideritem.id}_desc`)}</p>)}
    </AppContext.Consumer>
  );
}