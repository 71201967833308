import { Category } from './Category';

export const CategoriesData = [
    new Category("dukia", "", "", "dukia", 1, " images/thumbnails/fotos_kategorien/01.jpg", [
        new Category("bleiverglasung", "", "", "dukia/bleiverglasung", 11, "images/thumbnails/dukia/bleiverglasung/03_thumbnail.jpeg", []),
        new Category("brunnen", "", "", "dukia/brunnen", 8, "images/thumbnails/dukia/brunnen/04_thumbnail.jpeg", []),
        new Category("dallbeton", "", "", "dukia/dallbeton", 9, "images/thumbnails/dukia/dallbeton/04_thumbnail.jpeg", []),
        new Category("gedenkstatte", "", "", "dukia/gedenkstatte", 1, "images/thumbnails/dukia/gedenkstatte/01_thumbnail.jpeg", []),
        new Category("kreisverkehr", "", "", "dukia/kreisverkehr", 3, "images/thumbnails/dukia/kreisverkehr/01_thumbnail.jpeg", []),
        new Category("skulpturen", "", "", "dukia/skulpturen", 2, "images/thumbnails/dukia/skulpturen/01_thumbnail.jpeg", []),
        new Category("schilder", "", "", "dukia/schilder", 20, "images/thumbnails/dukia/schilder/05_thumbnail.jpeg", []),
        new Category("kunstverglasung", "", "", "dukia/kunstverglasung", 8, "images/thumbnails/dukia/kunstverglasung/01_thumbnail.jpeg", [])
        
    ]),
    new Category("dukii", "", "", "dukii", 1, "images/thumbnails/fotos_kategorien/02.jpg", [
        new Category("beleuchtung", "", "", "dukii/beleuchtung", 7, "images/thumbnails/dukii/beleuchtung/06_thumbnail.jpeg", []),
        new Category("crashglas", "", "", "dukii/crashglas", 2, "images/thumbnails/dukii/crashglas/01_thumbnail.jpeg", []),
        new Category("folien", "", "", "dukii/folien", 10, "images/thumbnails/dukii/folien/08_thumbnail.jpeg", []),
        new Category("fusing", "", "", "dukii/fusing", 18, "images/thumbnails/dukii/fusing/09_thumbnail.jpeg", []),
        new Category("glasbrucke", "", "", "dukii/glasbrucke", 3, "images/thumbnails/dukii/glasbrucke/01_thumbnail.jpeg", []),
        new Category("glasmobel", "", "", "dukii/glasmobel", 11, "images/thumbnails/dukii/glasmobel/06_thumbnail.jpeg", []),
        new Category("kunstverglasung", "", "", "dukii/kunstverglasung", 11, "images/thumbnails/dukii/kunstverglasung/01_thumbnail.jpeg", []),
        new Category("pokale", "", "", "dukii/pokale", 11, "images/thumbnails/dukii/pokale/10_thumbnail.jpeg", []),
        new Category("rillenschliff", "", "", "dukii/rillenschliff", 10, "images/thumbnails/dukii/rillenschliff/10_thumbnail.jpeg", []),
        new Category("sandstrahlung", "", "", "dukii/sandstrahlung", 10, "images/thumbnails/dukii/sandstrahlung/04_thumbnail.jpeg", []),
        new Category("skulptureninnen", "", "", "dukii/skulptureninnen", 11, "images/thumbnails/dukii/skulptureninnen/08_thumbnail.jpeg", []),
        new Category("spiegel", "", "", "dukii/spiegel", 15, "images/thumbnails/dukii/spiegel/01_thumbnail.jpeg", []),
        new Category("tapsstander", "", "", "dukii/tapsstander", 28, "images/thumbnails/dukii/tapsstander/02_thumbnail.jpeg", []),
        new Category("vitrinen", "", "", "dukii/vitrinen", 10, "images/thumbnails/dukii/vitrinen/04_thumbnail.jpeg", [])
    ]),
    new Category("gia", "", "", "gia", 1, "images/thumbnails/fotos_kategorien/03.jpg", [
        new Category("gelander", "", "", "gia/gelander", 20, "images/thumbnails/gia/gelander/01_thumbnail.jpeg", []),
        new Category("glasboden", "", "", "gia/glasboden", 8, "images/thumbnails/gia/glasboden/01_thumbnail.jpeg", []),
        new Category("isolierglas", "", "", "gia/isolierglas", 7, "images/thumbnails/gia/isolierglas/02_thumbnail.jpeg", []),
        new Category("steinschlagauto", "", "", "gia/steinschlagauto", 4, "images/thumbnails/gia/steinschlagauto/01_thumbnail.jpeg", []),
        new Category("vordacher", "", "", "gia/vordacher", 15, "images/thumbnails/gia/vordacher/05_thumbnail.jpeg", []),
        new Category("wintergarten", "", "", "gia/wintergarten", 8, "images/thumbnails/gia/wintergarten/07_thumbnail.jpeg", [])
    ]),
    new Category("gii", "", "", "gii", 1, "images/thumbnails/fotos_kategorien/04.jpg", [
        new Category("duschen", "", "", "gii/duschen", 51, "images/thumbnails/gii/duschen/07_thumbnail.jpeg", []),
        new Category("gelander", "", "", "gii/gelander", 57, "images/thumbnails/gii/gelander/07_thumbnail.jpeg", []),
        new Category("glasboden", "", "", "gii/glasboden", 11, "images/thumbnails/gii/glasboden/02_thumbnail.jpeg", []),
        new Category("glasturen", "", "", "gii/glasturen", 16, "images/thumbnails/gii/glasturen/09_thumbnail.jpeg", []),
        new Category("kuchenruckwande", "", "", "gii/kuchenruckwande", 36, "images/thumbnails/gii/kuchenruckwande/05_thumbnail.jpeg", []),
        new Category("schiebture", "", "", "gii/schiebture", 14, "images/thumbnails/gii/schiebture/10_thumbnail.jpeg", [])
    ]),
    new Category("ausstellung", "", "", "ausstellung", 0, "images/thumbnails/fotos_kategorien/05.jpg", []),
    new Category("bilderrahmen", "", "", "bilderrahmen", 16, "images/thumbnails/fotos_kategorien/06.jpg", [])
];

export const getCategoryById = (mainCatId: string, subCatId: string) => {
    const mainCat: Category | undefined = CategoriesData.find((cat: Category) => cat.id === mainCatId);

    if (!subCatId) {
        return mainCat;
    }

    if (mainCat && subCatId) {
        return mainCat.subCategories.find((subCat: Category) => subCat.id === subCatId);
    }

    return null;
};