export class SliderItem{
    public id: string;

    constructor(id: string){
        this.id = id;
    }

    public getImagePath = () => {
        return `images/slider/${this.id}.jpeg`;
    };
}

export const SliderItems = [
    new SliderItem('kuchenruckwande'),
    new SliderItem('duschen'),
    new SliderItem('infoschilder'),
    new SliderItem('glasbruch'),
    new SliderItem('gelander_innenbereich'),
    new SliderItem('ganzglasturen'),
    new SliderItem('pokale'),
    new SliderItem('windschutzscheibe'),
    new SliderItem('rahmen'),
    new SliderItem('spiegel'),
    new SliderItem('fusing'),
    new SliderItem('wintergarten'),
    new SliderItem('kreisverkehr'),
    new SliderItem('kunstverglasung'),
    new SliderItem('kunstambau'),
    new SliderItem('vordacher'),
    new SliderItem('kapselstander'),
    new SliderItem('aussengelander'),
    
]