import React from 'react';

export const Header = (props) => {
  return (
    <div>
      <img id="logo" src="images/logo.png" />
      <div className="row text-center">
        <div className="col-xs-12 col-sm-4 col-md-4">
          <div className="detail">
            <i className="glyphicon glyphicon-map-marker" />
            <span>A-2410 Hainburg Haydnplatz 2</span>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 col-md-4">
          <div className="detail">
            <i className="glyphicon glyphicon-earphone" />
            <a href="tel:0043216562239">(+43) 02165-62239</a>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 col-md-4">
          <div className="detail">
            <i className="glyphicon glyphicon-envelope" />
            <a href="mailto:office@glas-otto.at" id="email2">office@glas-otto.at</a>
          </div>
        </div>
      </div>
    </div>
  );
}