import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'

export const Footer = (props) => {
  return (
    <div>
      <div class="row" style={{paddingTop:'10px'}}>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="social-list">
            <li>
              <a target="_blank" href="http://firmena-z.wko.at/ecgInfo.asp?MGID=BB674663B650E75ACD39BB823ACAD192&NOFRAME=J">
                <img border="0" src="images/ecg.gif" width="169" height="23" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/glasbau.otto" target="_blank">
                <span class="fa-icon">
                  <FontAwesomeIcon icon={faFacebookF} />
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}