import React from 'react';
import MapContainer from './MapContainer';
import Fade from 'react-reveal/Fade';
import { AppContext } from '../data/Context';
import { render } from 'react-dom';

export const Contact = (props) => {
  return (
    <div>
      <ContactHeader />
      <ContactDetails />
      {/* <MessageForm /> */}
    </div>
  );
}

const ContactHeader = () => {
  return (
    <div className="text-center">
      <h1><AppContext.Consumer>{context => (context.lang.contactTitle)}</AppContext.Consumer></h1>
      <hr style={{ borderColor: 'Black' }} />
    </div>
  );
};

const ContactDetails = () => {
  return (
    <div className="row text-center">
      <div className="col-xs-12 col-sm-6 col-md-6" style={{ height: '400px' }}>
        <MapContainer />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-6">
        <Fade right>
          <div className="detail">
            <i className="glyphicon glyphicon-map-marker" />
            <span>A-2410 Hainburg Haydnplatz 2</span>
          </div>
          <div className="detail">
            <i className="glyphicon glyphicon-earphone" />
            <a href="tel:0043216562239">(+43) 02165-62239</a>
          </div>
          <div className="detail">
            <i className="glyphicon glyphicon-envelope" />
            <a href="mailto:office@glas-otto.at" id="email2">office@glas-otto.at</a>
          </div>
          <div className="detail">
            <span><AppContext.Consumer>{context => (context.lang.contactOpenHeader)}</AppContext.Consumer></span>
          </div>
          <div className="detail">
            <span><AppContext.Consumer>{context => (context.lang.contactOpenHeaderWeek)}</AppContext.Consumer></span>
          </div>
          <div className="detail">
            <span><AppContext.Consumer>{context => (context.lang.contactOpenHeaderWeekend)}</AppContext.Consumer></span>
          </div>
        </Fade>
      </div>
    </div>
  );
};

const MessageForm = () => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12">
        <div id="contact-form">
          <form method="post" action="contact.php" id="contact-us" accept-charset="UTF-8" novalidate="novalidate">
            <div class="col-md-5 col-sm-5 col-xs-12" data-animation="slideInLeft">
              <div class="form-group">
                <input type="text" name="fullname" class="form-control input-lg" placeholder="Name" />
              </div>
              <div class="form-group">
                <input type="email" name="email" class="form-control input-lg" placeholder="Email" />
              </div>
              <div class="form-group">
                <input type="text" name="stadt" class="form-control input-lg" placeholder="Ort" />
              </div>
              <div class="form-group">
                <input type="text" name="phone" class="form-control input-lg" placeholder="Telefon" />
              </div>
            </div>
            <div class="col-md-7 col-sm-7 col-xs-12" data-animation="slideInRight">
              <div class="form-group">
                <textarea name="message" class="form-control input-lg" placeholder="Nachricht" />
              </div>
            </div>
            <input type="submit" class="btn btn-custom up " value="Send Message" data-animation="fadeInUpBig" />
          </form>
        </div>
      </div>
    </div>
  );
}