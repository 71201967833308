import React from 'react';
import { AppContext } from '../data/Context';
import Fade from 'react-reveal/Fade';

export const Description = (props) => {
  return (
    <div>
      <div className="text-center">
        <AppContext.Consumer>{context => (<h1>{context.lang.descriptionHeader}</h1>)}</AppContext.Consumer>
        <hr style={{ borderColor: 'Black' }} />
        <AppContext.Consumer>{context => (<div dangerouslySetInnerHTML={{ __html: context.lang.descriptionContent }} />)}</AppContext.Consumer>
      </div>
      <div className="row">
        <Fade left>
          <div className="col-md-6 col-sm-6 col-xs-12 text-right">
            <AppContext.Consumer>{context => (<h4>{context.lang.chronicHeader}</h4>)}</AppContext.Consumer>
            <AppContext.Consumer>{context => (<div dangerouslySetInnerHTML={{ __html: context.lang.chronicDescription }} />)}</AppContext.Consumer>
          </div>
        </Fade>
        <Fade right>
          <div className="col-md-6 col-sm-6 col-xs-12 text-left">
            <AppContext.Consumer>{context => (<h4>{context.lang.leistungHeader}</h4>)}</AppContext.Consumer>
            <AppContext.Consumer>{context => (<div dangerouslySetInnerHTML={{ __html: context.lang.leistungDescription }} />)}</AppContext.Consumer>
          </div>
        </Fade>
      </div>
      <div className="row">
        <Fade left>
          <div className="col-md-6 col-sm-6 col-xs-12 text-right">
            <AppContext.Consumer>{context => (<h4>{context.lang.sampleHeader}</h4>)}</AppContext.Consumer>
            <AppContext.Consumer>{context => (<div dangerouslySetInnerHTML={{ __html: context.lang.sampleDescription }} />)}</AppContext.Consumer>
          </div>
        </Fade>
        <Fade right>
          <div className="col-md-6 col-sm-6 col-xs-12 text-left">
            <AppContext.Consumer>{context => (<h4>{context.lang.referenceHeader}</h4>)}</AppContext.Consumer>
            <AppContext.Consumer>{context => (<div dangerouslySetInnerHTML={{ __html: context.lang.referenceDescription }} />)}</AppContext.Consumer>
          </div>
        </Fade>
      </div>
    </div>
  );
}