export type LangType = {
    de: any,
    en: any
}

export const lang: LangType = {
    de: {
        id: 'de',
        menuHome: 'Home',
        menuProjects: 'Projekte',
        menuDescription: 'Über uns',
        menuProducts: 'Produkte',
        menuContact: 'Kontakt',
        menuHandguss: 'Handguss',

        projectHeader: 'Neueste Projekte',
        projectContent: ' <p class="about-text"><b>NEU:</b> Der Handabdruck von Ihnen oder Ihren Kindern abgeformt und in Glas gegossen.</p><p class="about-text">Ein ganz persönliches Unikat. Exclusiv bei Glasbau Otto.</p>',
        descriptionHeader: 'Glas',
        descriptionContent: '<p>Ein Werkstoff mit grosser Tradition und noch grösserer Zukunft</p>',
        chronicHeader: 'CHRONIK',
        chronicDescription: '<p>1911 gegründet von den Urgrosseltern Josef und Maria Sollak. 1960 übernommen von den Grosseltern Kurt und Trude Otto. 1990 übergeben an die Eltern Manfred und Doris Otto in Zusammenarbeit mit Sohn Christopher</p>',
        leistungHeader: 'LEISTUNG',
        leistungDescription: '<p>Rasche Reparatur von Glasschäden mit direkter Versicherungsabrechnung. Traditionelle Handwerkskunst in Verbindung mit modernem Design. Moderne Glasverarbeitung zu leistbaren, vernünftigen Preisen. Innovative, kreative Lösungsvorschläge für Ihre Vorstellungen</p>',
        sampleHeader: 'BEISPIELE',
        sampleDescription: '<p>Mit den folgenden Fotos möchten wir Ihnen eine Anregung und einen kleinen Einblick über unsere bereits geschaffenen Produkte und Arbeiten zeigen.</p>',
        referenceHeader: 'REFERENZEN',
        referenceDescription: '<p>Wettpunkt, Verband der Sozialversicherungen, Marchfeldschlösser, Kunsttrans, Palais Ferstl, Erzdiözese Wien RHEAImmorent Holding, Schweisstechnische Zentralanstalt, Lufthansa Wien, Donauturm, Raiffeisenbanken, Kniwi, und noch viele mehr!</p>',
        productsHeader: 'Produkte',
        contactTitle: 'Kontakt',
        contactOpenHeader: 'Öffnungszeiten',
        contactOpenHeaderWeek: 'Mo - Fr:	8:00 - 12:00 und 13:00 - 18:00',
        contactOpenHeaderWeekend: 'Sa: 9:00 - 12:00',

        slider_kuchenruckwande_title: 'Küchenrückwände',
        slider_duschen_title: 'Duschen',
        slider_infoschilder_title: 'Infoschilder, Hinweistafeln',
        slider_glasbruch_title: 'Glasbruch Notverglasung',
        slider_gelander_innenbereich_title: 'Geländer Innenbereich',
        slider_ganzglasturen_title: 'Ganzglastüren/Anlagen',
        slider_pokale_title: 'Pokale',
        slider_windschutzscheibe_title: 'Windschutzscheibe Steinschlag',
        slider_rahmen_title: 'Rahmen',
        slider_spiegel_title: 'Spiegel',
        slider_fusing_title: 'Fusing',
        slider_wintergarten_title: 'Wintergärten, Carport',
        slider_kreisverkehr_title: 'Kreisverkehr Göttlesbrunn',
        slider_kunstverglasung_title: 'Kunstverglasung',
        slider_kunstambau_title: 'Kunst am Bau',
        slider_vordacher_title: 'Vordächer',
        slider_kapselstander_title: 'Kapselständer',
        slider_aussengelander_title: 'Aussengeländer',
        slider_kuchenruckwande_desc: 'Digitaldruck oder Foliendruck unser Entwurf oder Ihr Foto, alles ist möglich',
        slider_duschen_desc: 'Wellness für das Auge mit Fotomotiven, Folien, Sandstrahlmotiven oder Farbglas',
        slider_infoschilder_desc: 'dem Stil des Hauses angepasst Lackiert, foliert oder beleuchtet',
        slider_glasbruch_desc: 'Direktabrechnung mit Ihrer Versicherung',
        slider_gelander_innenbereich_desc: 'Sicherheit und Design Nurglas oder Glas/Edelstahlausführung',
        slider_ganzglasturen_desc: 'für gewerbliche und private Nutzung Sicht-, Schall- und Klemmschutz',
        slider_pokale_desc: 'Individuell nach Ihre Wünsche angefertigt in Verbindung mit verschiedenen Materalien',
        slider_windschutzscheibe_desc: 'Reparaturdauer ca. 1-1,5 Stunden Direktabrechnung mit Ihrer Versicherung',
        slider_rahmen_desc: 'traditionell, modern, individuell über 1500 Rahmen zur Auswahl',
        slider_spiegel_desc: 'veredelt mit Facette, Bilderrahmen, Fusingglas, Sandstrahlung oder Rillenschliff',
        slider_fusing_desc: 'geformt, verschmolzen, verfärbt. Vasen, Schalen, Lampen, Türen, Skulpturen nach unseren oder Ihren Entwürfen',
        slider_wintergarten_desc: 'Neu oder Reparatur in Zusammenarbeit mit Schlosser u. Tischler',
        slider_kreisverkehr_desc: 'errichtet 2005',
        slider_kunstverglasung_desc: 'Handwerkskunst mit modernem Design Entwurf: H. Eichelmüller fertiggestellt 2007',
        slider_kunstambau_desc: 'Kindergarten Hainburg, Entwurf H. Eichelmüller Ausführung 2009',
        slider_vordacher_desc: 'jede mögliche Grösse, statische Berrechnung verschiedenste Edelstahlbefestigungen',
        slider_kapselstander_desc: 'Individuell, Einzelanfertigung nach Ihren Wunsch für jede Kapselsart möglich',
        slider_aussengelander_desc: 'Bruck/Leitha Stadttheater Sicherheitsglas mit Edelstahl',

        dukia_name: 'Design u. Kunst im Aussenbereich',
        dukia_desc: '',
        bleiverglasung_name: 'Bleiverglasung',
        bleiverglasung_desc: '<p>Entwurf und Ausführung lt. Ihren Angaben oder unseren Ideen. Neu- oder Restaurierung.</p>',
        brunnen_name: 'Brunnen',
        brunnen_desc: '<p>Ob Zimmerbrunnen oder Dreiländerbrunnen in Verbindung mit Edelstahl, Stein -oder Farbglas.</p>',
        dallbeton_name: 'Dall Beton',
        dallbeton_desc: '<p>Eine altbewährte Glastechnik mit bis zu 3 cm starken Glasbrocken in Verbindung mit Beton.</p>',
        gedenkstatte_name: 'Gedenkstätte',
        gedenkstatte_desc: '<p>Kriegerdenkmal errichtet 2013 in Edelstal</p>',
        kreisverkehr_name: 'Kreisverkehr',
        kreisverkehr_desc: '<p>Die Gläserne Traube - Kreisverkehr Göttlesbrunn NÖ , errichtet 2005</p>',
        skulpturen_name: 'Skulpturen',
        skulpturen_desc: '<p>In Verbindung mit Holz, Stein oder Edelstahl, individuell und beleuchtbar.</p><p>Jedes Stück ein Unikat.</p>',
        schilder_name: 'Schilder',
        schilder_desc: '<p>Informationstafeln, Hausnummernschilder oder Wegweiser nach Ihren Wünschen in jeder Größe möglich.</p>',
        kunstverglasung_name: 'Kunstverglasung',
        kunstverglasung_desc: '',

        dukii_name: 'Design u. Kunst im Innenbereich',
        dukii_desc: '',
        beleuchtung_name:'Beleuchtung',
        beleuchtung_desc:'<p>Individuelle Farb/Lichtgestaltung durch Verschmelzen verschiedener Farbgläser.</p><p>Jede Lampe ein Unikat.</p>',
        crashglas_name:'Crashglas',
        crashglas_desc:'<p>Eine dekorative Idee für Trennwände, Glasmöbel oder Duschtrennwände.</p>',
        folien_name:'Folien',
        folien_desc:'<p>Reiche Auswahl an Entwürfen für Türen, Spiegel, Duschen etc.</p><p>Jedes Stück ein Unikat.</p><div class="row"> <div class="col-md-offset-2 col-sm-offset-2 col-xs-12 col-sm-8 col-md-8 text-center"><a target="_blank" href="http://s326759274.online.de/firma/glaser-motive/index.php?firma=designstudios" alt="Motiv-Galerie"><img class="glaser-motive-banner" src="images/motive-banner-lang.jpg" alt="Motiv-Galerie" style="border: 0;"/></a></div></div>',
        fusing_name:'Fusing',
        fusing_desc:'<p>Heiß verschmolzenes und geformtes Glas nach unseren oder Ihren Entwürfen.</p>',
        glasbrucke_name:'Glasbrücke',
        glasbrucke_desc:'<p>Die gläserne Verbindung , Fertigstellung 2011, Wien.</p>',
        glasmobel_name:'Glasmöbel',
        glasmobel_desc:'<p>Glastisch bis Waffenschrank - fast alles ist möglich.</p>',
        pokale_name:'Pokale',
        pokale_desc:'<p>Individuell gestaltete Pokale für jeden Anlass in jeder Preisklasse.</p>',
        rillenschliff_name:'Rillenschliff',
        rillenschliff_desc:'<p>Reiche Auswahl an Entwürfen für Türen, Spiegel, Duschen Verschiedene Farbvariationen. </p><p>Größe und Form Ihren Vorstellungen angepasst</p><p>Kombinationsmöglichkeiten mit Bilderrahmen, Metall, Holz etc.</p><p>Kantenbearbeitung schlicht oder fasetiert</p><p>Oberflächenbearbeitung sandgestrahlt oder mit Rillenschliff versehen</p>',
        sandstrahlung_name:'Sandstrahlung',
        sandstrahlung_desc:'<p>Reiche Auswahl an Entwürfen für Türen, Spiegel, Duschen etc.</p><div class="row"> <div class="col-md-offset-2 col-sm-offset-2 col-xs-12 col-sm-8 col-md-8 text-center"><a target="_blank" href="http://s326759274.online.de/firma/glaser-motive/index.php?firma=designstudios" alt="Motiv-Galerie"><img class="glaser-motive-banner" src="images/motive-banner-lang.jpg" alt="Motiv-Galerie" style="border: 0;"/></a></div></div>',
        skulptureninnen_name:'Skulpturen',
        skulptureninnen_desc:'<p>In Verbindung mit Holz, Stein oder Edelstahl, individuell und beleuchtbar.</p><p>Jedes Stück ein Unikat.</p>',
        spiegel_name:'Spiegel',
        spiegel_desc:'<p>Größe und Form Ihren Vorstellungen angepasst</p><p>Kombinationsmöglichkeiten mit Bilderrahmen, Metall, Holz etc.</p><p>Kantenbearbeitung schlicht oder fasetiert</p><p>Oberflächenbearbeitung sandgestrahlt oder mit Rillenschliff versehen</p>',
        tapsstander_name:'Kapselständer',
        tapsstander_desc:'<p>Für jede Größe und Art von Taps in verschiedensten Ausführung.</p><p>Ihrer Küche, Ihrem Büro individuell angepasst.</p>',
        vitrinen_name:'Vitrinen',
        vitrinen_desc:'<p>Schauvitrinen, Hängevitrinen in jeder Größe</p>',

        gia_name: 'Glas im Aussenbereich',
        gia_desc: '',
        // gelander_name: 'Geländer',
        // gelander_desc: '',
        // glasboden_name: 'Glasboden',
        // glasboden_desc: '<p>Laut statischer Berechnungen mit oder ohne Rutschhemmung.</p>',
        isolierglas_name: 'Isolierglas',
        isolierglas_desc: '<p>Wärme/Einbruch/Schallschutzgläser.</p><p>Neu- oder Reparaturverglasung Direktabrechnung mit Ihrer Versicherung</p>',
        steinschlagauto_name: 'Steinschlag Auto',
        steinschlagauto_desc: '<p>Bei Steinschlag: Notrufnummer 02165/622 39 Direktabrechnung mit Versicherung Dauer: ca. 1 Stunde (nach Voranmeldung)</p>',
        vordacher_name: 'Vordächer',
        vordacher_desc: '<p>Verbundsicherheitsglas in Verbindung mit statisch geprüften Edelstahl-Konstruktionen in jeder Grösse.</p>',
        wintergarten_name: 'Wintergärten',
        wintergarten_desc: '<p>Verschiedenste Ausführungen in Holz oder Aluminium in Zusammenarbeit mit Tischlerei -und Metallbaubetrieben.</p>',

        gii_name: 'Glas im Innenbereich',
        gii_desc: '',
        duschen_name:'Duschen',
        duschen_desc: '<p>Fertigung von Duschen in jeder Größe in 8-12 mm Sicherheitsglas und verschiedensten Beschlagsarten.</p><div class="row"> <div class="col-md-offset-2 col-sm-offset-2 col-xs-12 col-sm-8 col-md-8 text-center"><a target="_blank" href="http://s326759274.online.de/firma/glaser-motive/index.php?firma=designstudios" alt="Motiv-Galerie"><img class="glaser-motive-banner" src="images/motive-banner-lang.jpg" alt="Motiv-Galerie" style="border: 0;"/></a></div></div>',
        gelander_name:'Geländer',
        gelander_desc: '<p>Nurglasgeländer in Kombination mit Edelstahl- oder Holzhandläufen.</p><p>Edelstahlgeländer mit Glasfüllungen in Rundrohr- oder Vierkantausfühning.</p><p>Glasstärke nach statischer Berechnung.</p>',
        glasboden_name:'Glasboden',
        glasboden_desc: '<p>Laut statischer Berechnungen mit oder ohne Rutschhemmung in verschidenem Design.</p>',
        glasturen_name:'Glastüren',
        glasturen_desc: '<p>Nurglastüren in Verbindung mit Farbbeschichtung, Sandstrahlung, Rillenschliff oder Dekorfolien. Montiert in Mauerleibung oder Zarge.</p><div class="row"> <div class="col-md-offset-2 col-sm-offset-2 col-xs-12 col-sm-8 col-md-8 text-center"><a target="_blank" href="http://s326759274.online.de/firma/glaser-motive/index.php?firma=designstudios" alt="Motiv-Galerie"><img class="glaser-motive-banner" src="images/motive-banner-lang.jpg" alt="Motiv-Galerie" style="border: 0;"/></a></div></div>',
        kuchenruckwande_name: 'Küchenrückwände',
        kuchenruckwande_desc: '<p>Industrie-Standardfarbgläser oder Weißglas mit Farbbeschichtung RAL Standardfarben nach Wahl, Spiegel, Foto-Glasdruck oder bedruckte Folie mit ihren Fotos.</p><p>Größe und Form Ihren Vorstellungen angepasst</p><p>Kombinationsmöglichkeiten mit Bilderrahmen, Metall, Holz etc.</p><p>Kantenbearbeitung schlicht oder fasetiert</p><p>Oberflächenbearbeitung sandgestrahlt oder mit Rillenschliff versehen</p>',
        schiebture_name: 'Schiebtüre',
        schiebture_desc: '<div class="row"> <div class="col-md-offset-2 col-sm-offset-2 col-xs-12 col-sm-8 col-md-8 text-center"><a target="_blank" href="http://s326759274.online.de/firma/glaser-motive/index.php?firma=designstudios" alt="Motiv-Galerie"><img class="glaser-motive-banner" src="images/motive-banner-lang.jpg" alt="Motiv-Galerie" style="border: 0;"/></a></div></div>',

        ausstellung_name: 'Ausstellungen',
        ausstellung_desc:'<div class="col-xs-12 col-sm-12 col-md-12"><img style="width:100%;" src="images/category_gallery/ausstellung/fk_kindl_desc.jpg" alt="FK Kindl"></div>',

        bilderrahmen_name: 'Bilderrahmen',
        bilderrahmen_desc: `<h3>Hier sind Ihre Bilder in den richtigen Händen!</h3>
        <h4>GCF (Guild Commanded Framer) Doris Otto seit 2008 die erste geprüfte österreichische Facheinrahmerin der britischen Kunstinnung.</h4>
        <p>1000 Leistenmuster zur Auswahl, kompetente Beratung und Fertigung, große Passepartoutauswahl, Spezialgläser zum Schutz ihrer Kunstwerke.</p>`,

        handguss_name: 'Handguss',
    },
    en: {
        id: 'en',
        menuHome: 'Home',
        menuProjects: 'Projects',
        menuDescription: 'About us',
        menuProducts: 'Products',
        menuContact: 'Contact',
        menuHandguss: 'Handguss',
        
        projectHeader: 'New Projects',
        projectContent: '<p>Here should be some english text</p>',
        descriptionHeader: 'English Glas',
        descriptionContent: '<p> English Ein Werkstoff mit grosser Tradition und noch grösserer Zukunft</p>',
        chronicHeader: 'English CHRONIK',
        chronicDescription: '<p>English 1911 gegründet von den Urgrosseltern Josef und Maria Sollak. 1960 übernommen von den Grosseltern Kurt und Trude Otto. 1990 übergeben an die Eltern Manfred und Doris Otto in Zusammenarbeit mit Sohn Christopher</p>',
        leistungHeader: 'English LEISTUNG',
        leistungDescription: '<p>English Rasche Reparatur von Glasschäden mit direkter Versicherungsabrechnung. Traditionelle Handwerkskunst in Verbindung mit modernem Design. Moderne Glasverarbeitung zu leistbaren, vernünftigen Preisen. Innovative, kreative Lösungsvorschläge für Ihre Vorstellungen</p>',
        sampleHeader: 'English BEISPIELE',
        sampleDescription: '<p>English Mit den folgenden Fotos möchten wir Ihnen eine Anregung und einen kleinen Einblick über unsere bereits geschaffenen Produkte und Arbeiten zeigen.</p>',
        referenceHeader: 'English REFERENZEN',
        referenceDescription: '<p>English  Wettpunkt, Verband der Sozialversicherungen, Marchfeldschlösser, Kunsttrans, Palais Ferstl, Erzdiözese Wien RHEAImmorent Holding, Schweisstechnische Zentralanstalt, Lufthansa Wien, Donauturm, Raiffeisenbanken, Kniwi, und noch viele mehr!</p>',
        productsHeader: 'Products',
        contactTitle: 'Contact',
        contactOpenHeader: 'English Öffnungszeiten',
        contactOpenHeaderWeek: 'English  Mo - Fr:	8:00 - 12:00 und 13:00 - 18:00',
        contactOpenHeaderWeekend: 'English Sa: 9:00 - 12:00',
        
        slider_kuchenruckwande_title: 'English Küchenrückwände',
        slider_duschen_title: 'English Duschen',
        slider_infoschilder_title: 'English Infoschilder, Hinweistafeln',
        slider_glasbruch_title: 'English lasbruch Notverglasung',
        slider_gelander_innenbereich_title: 'English Geländer Innenbereich',
        slider_ganzglasturen_title: 'English Ganzglastüren/Anlagen',
        slider_pokale_title: 'English Pokale',
        slider_windschutzscheibe_title: 'English Windschutzscheibe Steinschlag',
        slider_rahmen_title: 'English Rahmen',
        slider_spiegel_title: 'English Spiegel',
        slider_fusing_title: 'English Fusing',
        slider_wintergarten_title: 'English Wintergärten, Carport',
        slider_kreisverkehr_title: 'English Kreisverkehr Göttlesbrunn',
        slider_kunstverglasung_title: 'English Kunstverglasung',
        slider_kunstambau_title: 'English Kunst am Bau',
        slider_vordacher_title: 'English Vordächer',
        slider_kapselstander_title: 'English Kapselständer',
        slider_aussengelander_title: 'English Aussengeländer',
        slider_kuchenruckwande_desc: 'English Digitaldruck oder Foliendruck unser Entwurf oder Ihr Foto, alles ist möglich',
        slider_duschen_desc: 'English Wellness für das Auge mit Fotomotiven, Folien, Sandstrahlmotiven oder Farbglas',
        slider_infoschilder_desc: 'English dem Stil des Hauses angepasst Lackiert, foliert oder beleuchtet',
        slider_glasbruch_desc: 'English Direktabrechnung mit Ihrer Versicherung',
        slider_gelander_innenbereich_desc: 'English Sicherheit und Design Nurglas oder Glas/Edelstahlausführung',
        slider_ganzglasturen_desc: 'English für gewerbliche und private Nutzung Sicht-, Schall- und Klemmschutz',
        slider_pokale_desc: 'English Individuell nach Ihre Wünsche angefertigt in Verbindung mit verschiedenen Materalien',
        slider_windschutzscheibe_desc: 'English Reparaturdauer ca. 1-1,5 Stunden Direktabrechnung mit Ihrer Versicherung',
        slider_rahmen_desc: 'English traditionell, modern, individuell über 1500 Rahmen zur Auswahl',
        slider_spiegel_desc: 'English veredelt mit Facette, Bilderrahmen, Fusingglas, Sandstrahlung oder Rillenschliff',
        slider_fusing_desc: 'English geformt, verschmolzen, verfärbt. Vasen, Schalen, Lampen, Türen, Skulpturen nach unseren oder Ihren Entwürfen',
        slider_wintergarten_desc: 'English Neu oder Reparatur in Zusammenarbeit mit Schlosser u. Tischler',
        slider_kreisverkehr_desc: 'English errichtet 2005',
        slider_kunstverglasung_desc: 'English Handwerkskunst mit modernem Design Entwurf: H. Eichelmüller fertiggestellt 2007',
        slider_kunstambau_desc: 'English Kindergarten Hainburg, Entwurf H. Eichelmüller Ausführung 2009',
        slider_vordacher_desc: 'English jede mögliche Grösse, statische Berrechnung verschiedenste Edelstahlbefestigungen',
        slider_kapselstander_desc: 'English Individuell, Einzelanfertigung nach Ihren Wunsch für jede Kapselsart möglich',
        slider_aussengelander_desc: 'English Bruck/Leitha Stadttheater Sicherheitsglas mit Edelstahl',

        dukia_name: 'Design u. Kunst im Aussenbereich',
        dukia_desc: '',
        bleiverglasung_name: 'Bleiverglasung',
        bleiverglasung_desc: '<p>Entwurf und Ausführung lt. Ihren Angaben oder unseren Ideen. Neu- oder Restaurierung.</p>',
        brunnen_name: 'Brunnen',
        brunnen_desc: '<p>Ob Zimmerbrunnen oder Dreiländerbrunnen in Verbindung mit Edelstahl, Stein -oder Farbglas.</p>',
        dallbeton_name: 'Dall Beton',
        dallbeton_desc: '<p>Eine altbewährte Glastechnik mit bis zu 3 cm starken Glasbrocken in Verbindung mit Beton.</p>',
        gedenkstatte_name: 'Gedenkstätte',
        gedenkstatte_desc: '<p>Kriegerdenkmal errichtet 2013 in Edelstal</p>',
        kreisverkehr_name: 'Kreisverkehr',
        kreisverkehr_desc: '<p>Die Gläserne Traube - Kreisverkehr Göttlesbrunn NÖ , errichtet 2005</p>',
        skulpturen_name: 'Skulpturen',
        skulpturen_desc: '<p>In Verbindung mit Holz, Stein oder Edelstahl, individuell und beleuchtbar.</p><p>Jedes Stück ein Unikat.</p>',
        schilder_name: 'Schilder',
        schilder_desc: '<p>Informationstafeln, Hausnummernschilder oder Wegweiser nach Ihren Wünschen in jeder Größe möglich.</p>',
        kunstverglasung_name: 'Kunstverglasung',
        kunstverglasung_desc: '',
        
        dukii_name: 'English Design u. Kunst im Innenbereich',
        dukii_desc: 'English Design u. Kunst im Innenbereich',
        beleuchtung_name:'Beleuchtung',
        beleuchtung_desc:'<p>Individuelle Farb/Lichtgestaltung durch Verschmelzen verschiedener Farbgläser.</p><p>Jede Lampe ein Unikat.</p>',
        crashglas_name:'Crashglas',
        crashglas_desc:'<p>Eine dekorative Idee für Trennwände, Glasmöbel oder Duschtrennwände.</p>',
        folien_name:'Folien',
        folien_desc:'<p>Reiche Auswahl an Entwürfen für Türen, Spiegel, Duschen etc.</p><p>Jedes Stück ein Unikat.</p><div class="row"> <div class="col-md-offset-2 col-sm-offset-2 col-xs-12 col-sm-8 col-md-8 text-center"><a target="_blank" href="http://s326759274.online.de/firma/glaser-motive/index.php?firma=designstudios" alt="Motiv-Galerie"><img class="glaser-motive-banner" src="images/motive-banner-lang.jpg" alt="Motiv-Galerie" style="border: 0;"/></a></div></div>',
        fusing_name:'Fusing',
        fusing_desc:'<p>Heiß verschmolzenes und geformtes Glas nach unseren oder Ihren Entwürfen.</p>',
        glasbrucke_name:'Glasbrücke',
        glasbrucke_desc:'<p>Die gläserne Verbindung , Fertigstellung 2011, Wien.</p>',
        glasmobel_name:'Glasmöbel',
        glasmobel_desc:'<p>Glastisch bis Waffenschrank - fast alles ist möglich.</p>',
        pokale_name:'Pokale',
        pokale_desc:'<p>Individuell gestaltete Pokale für jeden Anlass in jeder Preisklasse.</p>',
        rillenschliff_name:'Rillenschliff',
        rillenschliff_desc:'<p>Reiche Auswahl an Entwürfen für Türen, Spiegel, Duschen Verschiedene Farbvariationen. </p><p>Größe und Form Ihren Vorstellungen angepasst</p><p>Kombinationsmöglichkeiten mit Bilderrahmen, Metall, Holz etc.</p><p>Kantenbearbeitung schlicht oder fasetiert</p><p>Oberflächenbearbeitung sandgestrahlt oder mit Rillenschliff versehen</p>',
        sandstrahlung_name:'Sandstrahlung',
        sandstrahlung_desc:'<p>Reiche Auswahl an Entwürfen für Türen, Spiegel, Duschen etc.</p><div class="row"> <div class="col-md-offset-2 col-sm-offset-2 col-xs-12 col-sm-8 col-md-8 text-center"><a target="_blank" href="http://s326759274.online.de/firma/glaser-motive/index.php?firma=designstudios" alt="Motiv-Galerie"><img class="glaser-motive-banner" src="images/motive-banner-lang.jpg" alt="Motiv-Galerie" style="border: 0;"/></a></div></div>',
        skulptureninnen_name:'Skulpturen',
        skulptureninnen_desc:'<p>In Verbindung mit Holz, Stein oder Edelstahl, individuell und beleuchtbar.</p><p>Jedes Stück ein Unikat.</p>',
        spiegel_name:'Spiegel',
        spiegel_desc:'<p>Größe und Form Ihren Vorstellungen angepasst</p><p>Kombinationsmöglichkeiten mit Bilderrahmen, Metall, Holz etc.</p><p>Kantenbearbeitung schlicht oder fasetiert</p><p>Oberflächenbearbeitung sandgestrahlt oder mit Rillenschliff versehen</p>',
        tapsstander_name:'Kapselständer',
        tapsstander_desc:'<p>Für jede Größe und Art von Taps in verschiedensten Ausführung.</p><p>Ihrer Küche, Ihrem Büro individuell angepasst.</p>',
        vitrinen_name:'Vitrinen',
        vitrinen_desc:'<p>Schauvitrinen, Hängevitrinen in jeder Größe</p>',

        gia_name: 'Glas im Aussenbereich',
        gia_desc: '',
        // gelander_name: 'Geländer',
        // gelander_desc: '',
        // glasboden_name: 'Glasboden',
        // glasboden_desc: '<p>Laut statischer Berechnungen mit oder ohne Rutschhemmung.</p>',
        isolierglas_name: 'Isolierglas',
        isolierglas_desc: '<p>Wärme/Einbruch/Schallschutzgläser.</p><p>Neu- oder Reparaturverglasung Direktabrechnung mit Ihrer Versicherung</p>',
        steinschlagauto_name: 'Steinschlag Auto',
        steinschlagauto_desc: '<p>Bei Steinschlag: Notrufnummer 02165/622 39 Direktabrechnung mit Versicherung Dauer: ca. 1 Stunde (nach Voranmeldung)</p>',
        vordacher_name: 'Vordächer',
        vordacher_desc: '<p>Verbundsicherheitsglas in Verbindung mit statisch geprüften Edelstahl-Konstruktionen in jeder Grösse.</p>',
        wintergarten_name: 'Wintergärten',
        wintergarten_desc: '<p>Verschiedenste Ausführungen in Holz oder Aluminium in Zusammenarbeit mit Tischlerei -und Metallbaubetrieben.</p>',
        
        gii_name: 'English Glas im Innenbereich',
        gii_desc: 'English Glas im Innenbereich',
        duschen_name:'English Duschen',
        duschen_desc: '<p>Fertigung von Duschen in jeder Größe in 8-12 mm Sicherheitsglas und verschiedensten Beschlagsarten.</p><div class="row"> <div class="col-md-offset-2 col-sm-offset-2 col-xs-12 col-sm-8 col-md-8 text-center"><a target="_blank" href="http://s326759274.online.de/firma/glaser-motive/index.php?firma=designstudios" alt="Motiv-Galerie"><img class="glaser-motive-banner" src="images/motive-banner-lang.jpg" alt="Motiv-Galerie" style="border: 0;"/></a></div></div>',
        gelander_name:'Geländer',
        gelander_desc: '<p>Nurglasgeländer in Kombination mit Edelstahl- oder Holzhandläufen.</p><p>Edelstahlgeländer mit Glasfüllungen in Rundrohr- oder Vierkantausfühning.</p><p>Glasstärke nach statischer Berechnung.</p>',
        glasboden_name:'Glasboden',
        glasboden_desc: '<p>Laut statischer Berechnungen mit oder ohne Rutschhemmung in verschidenem Design.</p>',
        glasturen_name:'Glastüren',
        glasturen_desc: '<p>Nurglastüren in Verbindung mit Farbbeschichtung, Sandstrahlung, Rillenschliff oder Dekorfolien. Montiert in Mauerleibung oder Zarge.</p><div class="row"> <div class="col-md-offset-2 col-sm-offset-2 col-xs-12 col-sm-8 col-md-8 text-center"><a target="_blank" href="http://s326759274.online.de/firma/glaser-motive/index.php?firma=designstudios" alt="Motiv-Galerie"><img class="glaser-motive-banner" src="images/motive-banner-lang.jpg" alt="Motiv-Galerie" style="border: 0;"/></a></div></div>',
        kuchenruckwande_name: 'Küchenrückwände',
        kuchenruckwande_desc: '<p>Industrie-Standardfarbgläser oder Weißglas mit Farbbeschichtung RAL Standardfarben nach Wahl, Spiegel, Foto-Glasdruck oder bedruckte Folie mit ihren Fotos.</p><p>Größe und Form Ihren Vorstellungen angepasst</p><p>Kombinationsmöglichkeiten mit Bilderrahmen, Metall, Holz etc.</p><p>Kantenbearbeitung schlicht oder fasetiert</p><p>Oberflächenbearbeitung sandgestrahlt oder mit Rillenschliff versehen</p>',
        schiebture_name: 'Schiebtüre',
        schiebture_desc: '<div class="row"> <div class="col-md-offset-2 col-sm-offset-2 col-xs-12 col-sm-8 col-md-8 text-center"><a target="_blank" href="http://s326759274.online.de/firma/glaser-motive/index.php?firma=designstudios" alt="Motiv-Galerie"><img class="glaser-motive-banner" src="images/motive-banner-lang.jpg" alt="Motiv-Galerie" style="border: 0;"/></a></div></div>',

        ausstellung_name: 'Ausstellungen',
        ausstellung_desc:'<div class="col-xs-12 col-sm-12 col-md-12"><img style="width:100%;" src="images/category_gallery/ausstellung/fk_kindl_desc.jpg" alt="FK Kindl"></div>',

        bilderrahmen_name: 'Bilderrahmen',
        bilderrahmen_desc: `<h3>Hier sind Ihre Bilder in den richtigen Händen!</h3>
        <h4>GCF (Guild Commanded Framer) Doris Otto seit 2008 die erste geprüfte österreichische Facheinrahmerin der britischen Kunstinnung.</h4>
        <p>1000 Leistenmuster zur Auswahl, kompetente Beratung und Fertigung, große Passepartoutauswahl, Spezialgläser zum Schutz ihrer Kunstwerke.</p>`,

        handguss_name: 'English Handguss',


    }
}