import React from 'react';
import Gallery from './Gallery';

export class CategoryGallery extends React.Component {
    constructor(props) {
        super(props);

        this.category = props.category;
    }

    getImagesSource = () => {
        const result = [];
        const mainPath = `images/category_gallery/${this.category.imagesPath}`;

        for (let index = 1; index <= this.category.imageCount; index++) {
            result.push({
                src: `${mainPath}/${index}.jpeg`,
                thumbnail:  `${mainPath}/${index}_thumbnail.jpeg`
            });
        }

        return result;
    };

    render() {
        return (
            this.category.imageCount > 0 
            ? <Gallery images={this.getImagesSource()} />
            : ""
        );
    }
}