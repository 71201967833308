import React, { Component } from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';

const mapStyles = {
    width: '90%',
    height: '400px'
  };

export class MapContainer extends Component {
    render() {
      return (
        <Map google={this.props.google} zoom={16}  style={mapStyles} initialCenter={{
            lat: 48.147978,
            lng: 16.945255,
            }} scrollwheel={false} zoomControl={false} mapTypeControl={false} streetViewControl={false}>
            <Marker name={'Glasbau Otto'} />
        </Map>
      );
    }
  }
   
  export default GoogleApiWrapper({
    apiKey: 'AIzaSyDaFRnaFDBdij-_PiNiQ7Oser5bC1VI3iI'
  })(MapContainer)