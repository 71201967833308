import React from 'react';
import { Link } from 'react-router-dom'
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import scrollToComponent from 'react-scroll-to-component';
import { AppContext } from '../data/Context';
import { CategoriesData } from '../data/CategoriesData'

export class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.sectionRefs = props.sections;
        this.changeLanguageCallBack = props.changeLanguage;
    }

    scrollToRef = (ref) => {
        scrollToComponent(ref, { offset: -50, align: 'top', duration: 500 });
    };

    changeLanguageOnClick = (lan) => {
        this.changeLanguageCallBack(lan);
        window.scrollTo({ top: -50, behavior: "smooth" });
    };

    render() {
        return (
            <Navbar fixedTop fluid>
                <Navbar.Header className="menuItem">
                    <AppContext.Consumer>{(context) =>
                        <Navbar.Brand >
                            <Link to={{ pathname: `/` }} onClick={() => { window.scrollTo({ top: -50, behavior: "smooth" }) }} >
                                {context.lang.menuHome}
                            </Link>
                        </Navbar.Brand>}
                    </AppContext.Consumer>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav>
                        <AppContext.Consumer>{(context) =>
                            (<NavItem className="menuItem" onClick={() => this.scrollToRef(this.sectionRefs.projectsRef)}>
                                {context.lang.menuProjects}
                            </NavItem>)}
                        </AppContext.Consumer>
                        <AppContext.Consumer>{(context) =>
                            (<NavItem className="menuItem" onClick={() => this.scrollToRef(this.sectionRefs.descriptionRef)}>
                                {context.lang.menuDescription}
                            </NavItem>)}
                        </AppContext.Consumer>
                        <AppContext.Consumer>
                            {(context) => (
                                <NavDropdown className="menuItem" title={context.lang.menuProducts} onClick={() => this.scrollToRef(this.sectionRefs.categoriesRef)}>
                                    {CategoriesData.map((data, index) => (<CategoryMenuItem key={index} category={data} />))}
                                    <MenuItem href="https://handguss.xyz" target="_blank">
                                    Handguss
                                    </MenuItem>
                                </NavDropdown>
                            )}
                        </AppContext.Consumer>
                        <AppContext.Consumer>{(context) =>
                            (<NavItem className="menuItem" href="https://handguss.xyz" target="_blank">
                                {context.lang.menuHandguss}
                            </NavItem>)}
                        </AppContext.Consumer>
                        <AppContext.Consumer>{(context) =>
                            (<NavItem className="menuItem" onClick={() => this.scrollToRef(this.sectionRefs.contactRef)}>
                                {context.lang.menuContact}
                            </NavItem>)}
                        </AppContext.Consumer>
                    </Nav>
                    <Nav pullRight>
                        <NavItem>
                            <Link to={{ pathname: `/` }} onClick={() => this.changeLanguageOnClick('de')}>DE</Link>|
                            <Link to={{ pathname: `/` }} onClick={() => this.changeLanguageOnClick('en')}>EN</Link>
                        </NavItem>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

class CategoryMenuItem extends React.Component {
    constructor(props) {
        super(props);
    }

    getLink = (maincatid, subcatid) => {
        if(maincatid && subcatid){
            return `/${maincatid}/${subcatid}`;
        }  

        return `/${maincatid}`;
    }

    render() {
        return (
            this.props.category.subCategories.length > 0
            ? (
                this.props.category.subCategories.map((data, index) => (<CategoryMenuItem key={index} category={data} mainCatId={this.props.category.id} />))
            )
            : (<MenuItem>
                <Link to={{ pathname: this.getLink(this.props.mainCatId ? this.props.mainCatId : this.props.category.id, this.props.mainCatId ? this.props.category.id : "") }}>
                    <AppContext.Consumer>
                        {context => (
                            <span>{context.getContent(`${this.props.category.id}_name`)}</span>
                        )}
                    </AppContext.Consumer>
                </Link>
            </MenuItem>)
        );
    }
}
