import React, { Component } from 'react';
import { RowComponent } from './components/RowComponent';
import { Header } from './components/Header';
import { SliderComponent } from './components/Slider';
import { Projects } from './components/Projects';
import { Description } from './components/Description';
import { Categories } from './components/Categories';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import { NavBar } from './components/NavBar';
import { AppContext, LanguageContext } from './data/Context';

class App extends Component {
  constructor(props) {
    super(props);
    this.sectionRefs = {
      projectsRef: null,
      descriptionRef: null,
      categoriesRef: null,
      contactRef: null
    };

    this.state = {
      languageContext: new LanguageContext('de'),
      counter: {
        desc: 'desc',
        value: 0
      }
    };
  }

  componentDidMount = () => {
  };

  changeLanguage = (lan) => {
    this.setState({ languageContext: new LanguageContext(lan) });
  };

  render() {
    return (
      <AppContext.Provider value={this.state.languageContext}>
        <div className="container">
          <NavBar sections={this.sectionRefs} changeLanguage={this.changeLanguage} />
          <div className="row container-glasbau">
            <div className="col-md-12">
              <RowComponent refFunc={() => { }} component={() => <Header />} id={'header'} />
              <RowComponent refFunc={() => { }} component={() => <SliderComponent />} id={'slider'} />
              <RowComponent refFunc={(section) => { this.sectionRefs.projectsRef = section; }} component={() => <Projects />} id={'projects'} />
              <RowComponent refFunc={(section) => { this.sectionRefs.descriptionRef = section; }} component={() => <Description />} id={'description'} />
              <RowComponent refFunc={(section) => { this.sectionRefs.categoriesRef = section; }} component={() => <Categories />} id={'categories'} />
              <RowComponent refFunc={(section) => { this.sectionRefs.contactRef = section; }} component={() => <Contact />} id={'contact'} />
              <RowComponent refFunc={() => { }} component={() => <Footer />} id={'footer'} />
            </div>
          </div>
        </div>
      </AppContext.Provider>
    );
  }
}

export default App;
