export class Category {
    public id: string;
    public name: string;
    public description: string;
    public imagesPath: string;
    public imageCount: number;
    public thumbnailPath: string;
    public subCategories: Category[];
    public isEmptyPlaceholder: boolean;

    constructor(id: string, name: string, desciption: string,
        imagesPath: string, imageCount: number, thumbnailPath: string,
        subCategories: Category[], isEmptyPlaceholder: boolean = false) {
        this.id = id;
        this.name = name;
        this.description = desciption;
        this.imagesPath = imagesPath;
        this.imageCount = imageCount;
        this.thumbnailPath = thumbnailPath;
        this.subCategories = subCategories;
        this.isEmptyPlaceholder = isEmptyPlaceholder || false;
    }
}